import { ColumnCode, OrderingColumn, OrderingType } from '@g17eco/types/custom-report';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, OnChangeForm, Option } from './types';
import { SelectFactory, SelectTypes, TableDraggableColumn, TableDraggableRows } from '@g17eco/molecules';
import { COLUMNS_MAP, DEFAULT_ORDERING_DIRECTION, getColumnOptions } from './utils';
import IconButton from '@components/button/IconButton';

type OptionValue = Option<false>['value'];

const draggableColumns: TableDraggableColumn[] = [
  {
    header: 'Heading',
  },
  {
    header: '',
    className: 'text-right',
  },
];

interface Props {
  open: boolean;
  toggle: () => void;
  form: Pick<Form, 'ordering' | 'columns'>;
  onChangeForm: OnChangeForm;
}
export const CustomOrderingModal = ({ open, toggle, form, onChangeForm }: Props) => {
  const { columns, ordering } = form;

  const [orderingColumns, setOrderingColumns] = useState<OrderingColumn[]>(() => {
    return ordering.type === OrderingType.Custom ? ordering.columns : [];
  });
  const [isChanged, setIsChanged] = useState(false);
  const changeOrderingColumns = (changeCallback: (newOrderingColumns: OrderingColumn[]) => OrderingColumn[]) => {
    setOrderingColumns(changeCallback);
    setIsChanged(true);
  };

  const [selectedColumn, setSelectedColumn] = useState<ColumnCode | undefined>(undefined);

  const handleSubmit = () => {
    onChangeForm({ ordering: { type: OrderingType.Custom, columns: orderingColumns } });
    toggle();
  };

  const options = getColumnOptions({
    columns: columns.filter(({ code }) => !orderingColumns.some((column) => column.code === code)),
  });
  const value = options.find((option) => option.value === selectedColumn);

  const handleClickOption = (code: OptionValue) => {
    setSelectedColumn(code);
  };

  const handleAddHeading = () => {
    if (!selectedColumn) {
      return;
    }

    changeOrderingColumns((orderingColumns) => [
      ...orderingColumns,
      { code: selectedColumn, direction: DEFAULT_ORDERING_DIRECTION },
    ]);
    setSelectedColumn(undefined);
  };

  const handleArrange = (newOrderingColumns: string[]) => {
    changeOrderingColumns((orderingColumns) =>
      newOrderingColumns.map(
        (newColumn) => orderingColumns.find((column) => column.code === newColumn) as OrderingColumn
      )
    );
  };

  const handleChangeDirection = (code: ColumnCode) => {
    changeOrderingColumns((orderingColumns) => {
      const index = orderingColumns.findIndex((column) => column.code === code);
      const newOrderingColumns = [...orderingColumns];
      newOrderingColumns[index].direction = newOrderingColumns[index].direction === 'asc' ? 'desc' : 'asc';
      return newOrderingColumns;
    });
  };

  const handleRemoveColumn = (code: ColumnCode) => {
    changeOrderingColumns((orderingColumns) => orderingColumns.filter((column) => column.code !== code));
  };

  const draggableRows = orderingColumns.map(({ code, direction }) => ({
    id: code,
    cols: [
      <div key={'header'}>{COLUMNS_MAP[code].header}</div>,
      <div key={'action'}>
        <Button
          key={'direction-btn'}
          color='link'
          onClick={() => handleChangeDirection(code)}
          className='text-decoration-none mr-4'
        >
          <i className={`fal ${direction === 'asc' ? 'fa-arrow-down-a-z' : 'fa-arrow-down-z-a'} mr-1`} />
          {direction === 'asc' ? 'asc' : 'desc'}
        </Button>
        <IconButton
          key={'delete-btn'}
          icon='fal fa-trash text-danger'
          color='link'
          onClick={() => handleRemoveColumn(code)}
        />
      </div>,
    ],
  }));

  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Order report headings</ModalHeader>
      <ModalBody>
        <TableDraggableRows columns={draggableColumns} data={draggableRows} handleUpdate={handleArrange} />
        <div className='d-flex justify-content-between align-items-stretch'>
          <SelectFactory<OptionValue>
            key={`SelectOrderingColumn-${options.length}`} // To clear the option when click add heading.
            selectType={SelectTypes.SingleSelect}
            isClearable={false}
            options={options}
            onChange={(op) => (op ? handleClickOption(op.value) : undefined)}
            value={value}
            placeholder='Select a heading'
            className='w-100 mr-2'
          />
          <Button className='flex-shrink-0' onClick={handleAddHeading} disabled={!selectedColumn}>
            <i className='fal fa-plus mr-2' />
            Add heading
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={Boolean(!orderingColumns.length) || !isChanged}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

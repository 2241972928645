import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { Form, OnChangeForm, Option } from './types';
import { DEFAULT_ORDERING_DIRECTION, getColumnOptions } from './utils';
import { OrderingType } from '@g17eco/types/custom-report';
import IconButton from '@components/button/IconButton';
import { CustomOrderingModal } from './CustomOrderingModal';
import { useToggle } from '@hooks/useToggle';

type OptionValue = Option<true>['value'];

interface Props {
  form: Pick<Form, 'columns' | 'ordering'>;
  onChangeForm: OnChangeForm;
}

export const OrderingSetting = ({ form, onChangeForm }: Props) => {
  const { columns, ordering } = form;
  const { type, columns: orderingColumns } = ordering;

  const [openCustomModal, toggleCustomModal] = useToggle();

  const orderingColumnCode = orderingColumns[0]?.code ?? columns[0]?.code;
  const orderingColumnDirection = orderingColumns[0]?.direction ?? DEFAULT_ORDERING_DIRECTION;

  const options = getColumnOptions({ columns, hasCustomOption: true });
  const optionValue = type === OrderingType.Custom ? 'custom' : orderingColumnCode;
  const value = options.find((option) => optionValue === option.value);

  const handleClickOption = (code: OptionValue) => {
    if (code === 'custom') {
      toggleCustomModal();
      return;
    }

    onChangeForm({ ordering: { type: OrderingType.Default, columns: [{ code, direction: orderingColumnDirection }] } });
  };

  const handleClickDirection = () => {
    if (type === OrderingType.Custom) {
      return;
    }

    onChangeForm({
      ordering: {
        type,
        columns: [{ code: orderingColumnCode, direction: orderingColumnDirection === 'asc' ? 'desc' : 'asc' }],
      },
    });
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='text-nowrap me-2'>Order report by:</span>
        <SelectFactory<OptionValue>
          key={`OrderingSelect-${openCustomModal}`} // To force re-render so it can be selected again after CustomOrderingModal is closed
          selectType={SelectTypes.SingleSelect}
          isClearable={false}
          options={options}
          onChange={(op) => (op ? handleClickOption(op.value) : undefined)}
          value={value}
        />
        <IconButton
          icon={`fal ${orderingColumnDirection === 'asc' ? 'fa-arrow-down-a-z' : 'fa-arrow-down-z-a'}`}
          className='ms-2'
          onClick={handleClickDirection}
          disabled={type === OrderingType.Custom}
        />
      </div>
      <CustomOrderingModal
        key={`CustomOrderingModal-${openCustomModal}`}
        open={openCustomModal}
        toggle={toggleCustomModal}
        form={form}
        onChangeForm={onChangeForm}
      />
    </>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import G17Client from '../../../services/G17Client';
import { getESG30 } from '../outputs';
import { GenerateCharts } from './GenerateCharts';
import { ReportData } from '../../../types/reportData';
import Loader from '../../loader';
import { DownloadButton } from '../../button/DownloadButton';
import { getAnalytics } from '../../../services/analytics/AnalyticsService';
import { AnalyticsEvents } from '../../../services/analytics/AnalyticsEvents';
import { DownloadSettingModal, DownloadSettingPropType } from '../../downloads/DownloadSettingModal';
import {
  convertToUtrvStatuses,
  downloadReportHandler,
  DownloadSettingsType,
  DownloadUtrvStatusCombined,
  DownloadXlsxOrCsv,
} from '../../downloads/util/downloadReportHandler';
import { UtrvStatus } from '../../../constants/status';
import { DownloadMultiScope, DownloadType, ScopeTotals, VisibilityStatus } from '@g17eco/types/download';
import { handleSettingsChange } from '@components/downloads/util/custom';
import { getDocVisibility } from '../sgx-metric-report/downloadScope';
import { ScopeGroups } from '../../../model/surveyData';
import { reportMultiScopeData } from '../reportApi';
import { GHGEmissionsDataSource } from './data/GHGEmissions';
import { EnergyConsumptionDataSource } from './data/EnergyConsumption';
import { EnergyIntensityRatioDataSource } from './data/EnergyIntensityRatio';
import { WaterConsumptionDataSource } from './data/WaterConsumption';
import { WasteGeneratedDataSource } from './data/WasteGenerated';
import { GenderSplitDataSource } from './data/GenderSplit';
import { GenderSplitPcDataSource } from './data/GenderSplitPc';
import { GenderPayGapDataSource } from './data/GenderPayGap';
import { AverageTrainingDataSource } from './data/AverageTraining';
import { BoardGenderDataSource } from './data/BoardGender';
import { BoardDiversityDataSource } from './data/BoardDiversity';
import { loggerMessage } from '../../../logger';

interface Props {
  initiativeId: string;
  surveyId: string;
  downloadXlsxOrCsv?: DownloadXlsxOrCsv;
  totals?: ScopeTotals;
  defaultDownloadSettings?: Partial<DownloadSettingsType>;
}

export interface DataSource {
  loaded: boolean;
  width?: number;
  height?: number;
  chart?: string;
}

export interface Esg30DataSources {
  ghgEmissions: GHGEmissionsDataSource;
  energyConsumption: EnergyConsumptionDataSource;
  energyIntensityRatio: EnergyIntensityRatioDataSource;
  waterConsumption: WaterConsumptionDataSource;
  wasteGenerated: WasteGeneratedDataSource;
  genderSplit: GenderSplitDataSource;
  genderSplitPc: GenderSplitPcDataSource;
  genderPayGap: GenderPayGapDataSource;
  averageTraining: AverageTrainingDataSource;
  boardGender: BoardGenderDataSource;
  boardDiversity: BoardDiversityDataSource;
}
const initialDataState = {
  loaded: false,
};

export const initialDataSourcesState: Esg30DataSources = {
  ghgEmissions: initialDataState,
  energyConsumption: initialDataState,
  energyIntensityRatio: initialDataState,
  waterConsumption: initialDataState,
  wasteGenerated: initialDataState,
  genderSplit: initialDataState,
  genderSplitPc: initialDataState,
  genderPayGap: initialDataState,
  averageTraining: initialDataState,
  boardGender: initialDataState,
  boardDiversity: initialDataState,
};

const type = 'esg30';

export const ESG30DownloadButton = ({ surveyId, initiativeId, totals, defaultDownloadSettings, ...downloadHandlers }: Props) => {
  const [downloading, setDownloading] = useState(false);
  const [questionData, setQuestionData] = useState<ReportData[]>();
  const [dataSourcesState, setData] = useState(initialDataSourcesState);
  const [isLoaded, setLoaded] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const handleCloseModal = () => setOpenSettings(false);
  const handleOpenSettings = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.blur();
    }
    setOpenSettings(true);
  };

  const [downloadSettings, setDownloadSettings] = useState<DownloadSettingsType>({
    selectedScopes: [],
    visibility: VisibilityStatus.ExcludeData,
    status: DownloadUtrvStatusCombined.All,
    ...defaultDownloadSettings,
  });

  const analytics = getAnalytics();

  const downloadDocx = async () => {
    setDownloading(true);
    try {
      const appliedStatus = downloadSettings.assuranceStatus ?? downloadSettings.status;
      const statuses = convertToUtrvStatuses(appliedStatus);
      const downloadScope: DownloadMultiScope = {
        scope: {
          [ScopeGroups.Frameworks]: [type],
          [ScopeGroups.Standards]: [],
          [ScopeGroups.Custom]: [],
          [ScopeGroups.Sdg]: [],
        },
        visibility: getDocVisibility(downloadSettings.visibility),
        targets: true, // Include targets
        ...statuses,
      };
      const data = await reportMultiScopeData(surveyId, downloadScope);
      setQuestionData(data);
    } catch (error) {
      loggerMessage(error.message);
    }

    return analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
      initiativeId,
      surveyId: surveyId,
      scopeValue: type,
      source: 'downloads_page',
      type: 'docx',
    });
  };

  const updateData = useCallback(
    (key: string, data: DataSource) => {
      setData((dataSources) => ({
        ...dataSources,
        [key]: data,
      }));
    },
    [setData]
  );

  useEffect(() => {
    const allLoaded = Object.keys(dataSourcesState).every((k) => dataSourcesState[k as keyof Esg30DataSources].loaded);
    setLoaded(allLoaded);
  }, [dataSourcesState]);

  useEffect(() => {
    if (isLoaded && downloading && questionData) {
      G17Client.getSurveyListItem(surveyId)
        .then(survey => getESG30({
          survey,
          dataSources: dataSourcesState,
          questionData,
          options: { displayUserInput: true },
          visibility: getDocVisibility(downloadSettings.visibility),
        }))
        .then(() => {
          setDownloading(false);
          setData(initialDataSourcesState);
        })
        .catch(e => loggerMessage(e.message, { surveyId }));
    }
  }, [downloadSettings.visibility, dataSourcesState, surveyId, isLoaded, downloading, questionData])

  const downloadModalProps: DownloadSettingPropType = {
    title: 'ESG Essentials report',
    isOpen: openSettings,
    handleClose: handleCloseModal,
    onDownload: (type: DownloadType) =>
      downloadReportHandler({ type, code: type, downloadSettings, ...downloadHandlers, downloadDocx }),
    handleChange: (event) => handleSettingsChange(event, setDownloadSettings, downloadSettings),
    downloadSettings,
    totals,
  };

  return (
    <div className='esg30-report-generator mt-5'>
      {downloading ? <Loader /> : <></>}
      {questionData && !isLoaded ? <GenerateCharts updateData={updateData} questionData={questionData} /> : <></>}
      <DownloadButton
        testId={'download-report-btn-esg30'}
        className='doc-btn'
        color='secondary'
        outline={true}
        onClick={handleOpenSettings}
      >
        Download report <i className='fas fa-file-download ml-2' />
      </DownloadButton>
      <DownloadSettingModal {...downloadModalProps} />
    </div>
  );
};
